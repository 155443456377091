import {
  faCheck,
  faChevronDown,
  faXmark
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'
import {
  type ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import {
  errorMessage,
  inputError,
  inputLabel,
  inputWrapper
} from '~/components/Form/Input/TextInput.css'
import type { InputInFormProps } from '~/components/Form/Input/input'
import { useOnExternalClick } from '~/utils/interactions'
import {
  checkmark,
  dropDownValueBox,
  multiSelectContainer,
  openIcon,
  openIconOpen,
  optionItem,
  optionItemNotSelectable,
  optionsWrapper,
  optionsWrapperLeft,
  optionsWrapperSmall,
  removeTagIcon,
  selectedTag,
  valueBoxActive,
  wrapper,
  wrapperInline
} from './Dropdown.css'
import {
  valueBoxFullWidth,
  valueBoxInline,
  valueBoxPlaceholder,
  valueBoxSmall,
  valueBoxText
} from './Input.css'

type BaseDropdownProps = InputInFormProps & {
  name?: string
  options: Array<{ label: string; value: string }>
  label?: string
  small?: boolean
  fullWidth?: boolean
  placeholder?: string
  emptyMessage?: string
  valueBoxClassName?: string
  error?: null | { message: string }
  asMenu?: boolean
}

type SingleDropdownProps = BaseDropdownProps & {
  multiple?: false
  defaultValue?: string | null
  defaultValues?: never
  onChange?: (value: string) => void
}

type MultiDropdownProps = BaseDropdownProps & {
  multiple: true
  defaultValue?: never
  defaultValues?: string[] | null
  onChange?: (value: string[]) => void
}

export type DropdownProps = SingleDropdownProps | MultiDropdownProps

export const Dropdown = ({
  name,
  options,
  defaultValue,
  defaultValues,
  label,
  onChange,
  small = false,
  fullWidth = false,
  placeholder,
  emptyMessage = 'Aucune option disponible',
  valueBoxClassName,
  register,
  watch,
  setValue,
  error = null,
  multiple = false,
  asMenu = false
}: DropdownProps): ReactElement => {
  const getValuesFromDefaultValues = useCallback(() => {
    if (multiple) {
      return defaultValues || []
    }
    return defaultValue ? [defaultValue] : []
  }, [defaultValue, defaultValues, multiple])

  const [selectedValues, setSelectedValues] = useState<string[]>(
    getValuesFromDefaultValues()
  )

  const [isOpen, setIsOpen] = useState(false)
  const [openLeft, setOpenLeft] = useState(false)
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const dropdownRef = useRef<HTMLDivElement | null>(null)

  if (register !== undefined && name !== undefined) {
    register(name)
  }

  useEffect(() => {
    setSelectedValues(getValuesFromDefaultValues())
    if (setValue && name) {
      setValue(
        name,
        multiple
          ? getValuesFromDefaultValues()
          : getValuesFromDefaultValues()[0]
      )
    }
  }, [getValuesFromDefaultValues, setValue, name, multiple])

  useOnExternalClick(wrapperRef, () => setIsOpen(false))

  useEffect(() => {
    if (dropdownRef.current === null || !isOpen) {
      setOpenLeft(false)
      return
    }
    const { x, width } = dropdownRef.current.getBoundingClientRect()
    setOpenLeft(x + width > window.innerWidth)
  }, [isOpen])

  const updateSelectedValues = (values: string[]) => {
    setSelectedValues(values)

    if (setValue && name) {
      setValue(name, multiple ? values : (values[0] ?? ''))
    }

    if (onChange && multiple) {
      ;(onChange as (value: string[]) => void)(values)
    } else if (onChange && !multiple) {
      ;(onChange as (value: string) => void)(values[0] ?? '')
    }
  }

  const handleSelection = (option: {
    label: string
    value: string
  }) => {
    let newValues: string[]

    if (!multiple && selectedValues.includes(option.value)) {
      return
    }

    if (selectedValues.includes(option.value)) {
      // If the value is already selected, remove it
      newValues = selectedValues.filter((v) => v !== option.value)
    } else if (!multiple) {
      // If the value is not selected and it's a single selection, set the value
      newValues = [option.value]
    } else {
      // Otherwise, add it
      newValues = [...selectedValues, option.value]
    }

    updateSelectedValues(newValues)

    if (!multiple) {
      setIsOpen(false)
    }
  }

  const removeValue = (value: string) => {
    const newValues = selectedValues.filter((v) => v !== value)
    updateSelectedValues(newValues)
  }

  const DropdownEl = (
    <>
      <div
        className={clsx(wrapper, !label && !fullWidth ? wrapperInline : null)}
        ref={wrapperRef}
      >
        <div
          className={clsx(
            dropDownValueBox,
            isOpen ? valueBoxActive : null,
            selectedValues.length === 0 && !asMenu ? valueBoxPlaceholder : null,
            small ? valueBoxSmall : null,
            fullWidth ? valueBoxFullWidth : null,
            !label ? valueBoxInline : null,
            valueBoxClassName,
            error !== null && inputError
          )}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div
            className={clsx(
              valueBoxText,
              multiple ? multiSelectContainer : null
            )}
          >
            {selectedValues.length === 0
              ? placeholder
              : selectedValues.map((value) => (
                  <div
                    key={value}
                    className={multiple ? selectedTag : undefined}
                    onClick={() =>
                      multiple ? removeValue(value) : setIsOpen(false)
                    }
                  >
                    {options.find((opt) => opt.value === value)?.label}
                    {multiple && (
                      <FontAwesomeIcon
                        icon={faXmark}
                        className={removeTagIcon}
                      />
                    )}
                  </div>
                ))}
          </div>
          <FontAwesomeIcon
            icon={faChevronDown}
            className={clsx(openIcon, isOpen ? openIconOpen : null)}
          />
        </div>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              className={clsx(
                optionsWrapper,
                small ? optionsWrapperSmall : null,
                openLeft ? optionsWrapperLeft : null
              )}
              ref={dropdownRef}
              key={name}
            >
              {options.length > 0 ? (
                options.map((option) => (
                  <div
                    key={option.value}
                    className={clsx(
                      optionItem,
                      !multiple && selectedValues.includes(option.value)
                        ? optionItemNotSelectable
                        : null
                    )}
                    onClick={() => handleSelection(option)}
                  >
                    {option.label}
                    {selectedValues.includes(option.value) && !asMenu && (
                      <span className={checkmark}>
                        <FontAwesomeIcon icon={faCheck} />
                      </span>
                    )}
                  </div>
                ))
              ) : (
                <div className={clsx(optionItem, optionItemNotSelectable)}>
                  {emptyMessage}
                </div>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      {error !== null && (
        <div className={errorMessage} role="alert">
          {error.message}
        </div>
      )}
    </>
  )

  if (!label) {
    return DropdownEl
  }
  return (
    <div className={inputWrapper}>
      {label && (
        <label
          htmlFor={name}
          className={inputLabel}
          onClick={(e) => {
            e.stopPropagation()
            setIsOpen(!isOpen)
          }}
        >
          {label}
        </label>
      )}
      {DropdownEl}
    </div>
  )
}
