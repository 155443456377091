import { faPaperclip } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type ReactElement, useRef } from 'react'
import { useFieldArray } from 'react-hook-form'
import type { Control } from 'react-hook-form/dist/types/form'
import type { InputInFormProps } from '~/components/Form/Input/input'
import type { AttachmentField } from './AttachmentsInput'

type AttachmentAddProps = InputInFormProps & {
  control?: Control<{
    attachments: AttachmentField[]
  }>
  className?: string
}

export const AttachmentAddInput = ({
  control,
  className
}: AttachmentAddProps): ReactElement => {
  const fileInput = useRef<HTMLInputElement>(null)
  if (control === undefined) {
    throw new Error('AttachmentAddInput must live inside a Form component')
  }
  const { append } = useFieldArray({
    control,
    name: 'attachments'
  })

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files) {
      for (const file of files) {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          append({
            fromTemplate: false,
            id: Date.now().toString(),
            object: {
              base64_content: (reader.result as string).split(',')[1] ?? '',
              filename: file.name,
              size: file.size,
              mime: file.type
            }
          })
        }
      }
      // Reset the input value to allow the same file to be selected again
      event.target.value = ''
    }
  }

  return (
    <>
      <input
        type="file"
        hidden
        ref={fileInput}
        multiple
        onChange={handleFileChange}
      />
      <button
        type="button"
        className={className}
        onClick={() => fileInput.current?.click()}
      >
        <FontAwesomeIcon icon={faPaperclip} />
      </button>
    </>
  )
}
