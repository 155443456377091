import clsx from 'clsx'
import type React from 'react'
import type { ReactElement } from 'react'
import { badge, badgeCircle, badgeReverse, badgeText } from './Badge.css'

interface BadgeItemProps {
  title: string
  backgroundColor: string
  reverse?: boolean
  maxWidth?: number
}

export const Badge = ({
  title,
  backgroundColor,
  reverse,
  maxWidth
}: BadgeItemProps): ReactElement => {
  return (
    <span className={clsx(badge, reverse ? badgeReverse : null)} title={title}>
      <span
        className={badgeCircle}
        style={{
          backgroundColor: backgroundColor
        }}
      />
      <span
        className={badgeText}
        style={{
          maxWidth: maxWidth ? `${maxWidth}px` : 'none'
        }}
      >
        {title}
      </span>
    </span>
  )
}
