import type { EmojiItem } from '@tiptap-pro/extension-emoji/dist/tiptap-pro/packages/extension-emoji/src/emoji'
import clsx from 'clsx'
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'
import {
  emojiButton,
  emojiButtonSelected,
  emojiDropdown,
  emojiImage
} from '~/components/Form/Editor/Emoji/EmojiList.css'
import { themeClass } from '~/styles/theme.css'

export const EmojiList = forwardRef(
  (
    {
      items,
      command
    }: {
      items: EmojiItem[]
      command: ({ name }: { name: string }) => void
    },
    ref
  ) => {
    const [selectedIndex, setSelectedIndex] = useState(0)

    const selectItem = useCallback(
      (index: number) => {
        const item = items[index]

        if (item) {
          command({ name: item.name })
        }
      },
      [command, items]
    )

    useEffect(() => setSelectedIndex(0), [])

    useImperativeHandle(
      ref,
      () => ({
        onKeyDown: ({ event }: { event: KeyboardEvent }) => {
          if (event.key === 'ArrowUp') {
            setSelectedIndex((selectedIndex + items.length - 1) % items.length)
            return true
          }

          if (event.key === 'ArrowDown') {
            setSelectedIndex((selectedIndex + 1) % items.length)
            return true
          }

          if (event.key === 'Enter') {
            selectItem(selectedIndex)
            return true
          }
        }
      }),
      [items, selectedIndex, selectItem]
    )

    return (
      <div className={clsx(themeClass, emojiDropdown)}>
        {items.map((item, index) => (
          <button
            className={clsx(
              emojiButton,
              index === selectedIndex ? emojiButtonSelected : null
            )}
            type="button"
            key={item.name}
            onClick={() => selectItem(index)}
          >
            {item.fallbackImage ? (
              <img
                src={item.fallbackImage}
                alt={item.name}
                className={emojiImage}
              />
            ) : (
              item.emoji
            )}
          </button>
        ))}
      </div>
    )
  }
)
