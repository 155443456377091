import {
  faBold,
  faItalic,
  faList,
  faTextSize,
  faUnderline
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Emoji from '@tiptap-pro/extension-emoji'
import Placeholder from '@tiptap/extension-placeholder'
import Underline from '@tiptap/extension-underline'
import {
  BubbleMenu,
  type Editor,
  EditorContent,
  useEditor,
  useEditorState
} from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import clsx from 'clsx'
import type React from 'react'
import { type ReactElement, useEffect } from 'react'
import emojiSuggestion from '~/components/Form/Editor/Emoji/suggestion'
import { AttachmentAddInput } from '~/components/Form/Input/AttachmentAddInput'
import { AttachmentsInput } from '~/components/Form/Input/AttachmentsInput'
import {
  editorBody,
  editorBottom,
  editorBubbleMenu,
  editorButtonToggle,
  editorButtonToggleActive,
  editorContainer,
  editorEmpty,
  editorMenuBottom
} from '~/components/Form/Input/EditorInput.css'
import type { InputInFormProps } from '~/components/Form/Input/input'
import {
  errorMessage,
  inputError,
  inputLabel,
  inputWrapper
} from './TextInput.css'

type EditorInputProps = React.HTMLProps<HTMLDivElement> &
  InputInFormProps & {
    label?: string
    name?: string
    error?: null | { message: string }
    contextInfo?: string
    hasAttachments?: boolean
  }

const extensions = [
  StarterKit,
  Underline,
  Placeholder.configure({
    placeholder: 'Saisir votre message...',
    emptyEditorClass: editorEmpty
  }),
  Emoji.configure({
    enableEmoticons: true,
    suggestion: emojiSuggestion
  })
]

const BaseModifiers = ({ editor }: { editor: Editor }) => {
  const editorState = useEditorState({
    editor,
    selector: ({ editor }) => ({
      isBold: editor.isActive('bold'),
      isItalic: editor.isActive('italic'),
      isUnderline: editor.isActive('underline'),
      isHeading: editor.isActive('heading'),
      isList: editor.isActive('bulletList')
    })
  })
  return (
    <>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={clsx(
          editorButtonToggle,
          editorState.isList ? editorButtonToggleActive : null
        )}
      >
        <FontAwesomeIcon icon={faList} />
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className={clsx(
          editorButtonToggle,
          editorState.isItalic ? editorButtonToggleActive : null
        )}
      >
        <FontAwesomeIcon icon={faItalic} />
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        className={clsx(
          editorButtonToggle,
          editorState.isUnderline ? editorButtonToggleActive : null
        )}
      >
        <FontAwesomeIcon icon={faUnderline} />
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={clsx(
          editorButtonToggle,
          editorState.isBold ? editorButtonToggleActive : null
        )}
      >
        <FontAwesomeIcon icon={faBold} />
      </button>
      <button
        type="button"
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        className={clsx(
          editorButtonToggle,
          editorState.isHeading ? editorButtonToggleActive : null
        )}
      >
        <FontAwesomeIcon icon={faTextSize} />
      </button>
    </>
  )
}

export const EditorInput = ({
  control,
  register,
  label,
  name,
  error = null,
  setValue,
  hasAttachments = false,
  watch
}: EditorInputProps): ReactElement => {
  if (
    register === undefined ||
    control === undefined ||
    setValue === undefined ||
    name === undefined ||
    watch === undefined
  ) {
    throw new Error('EditorInput must live inside a Form component')
  }
  register(name)
  const content = watch(name)
  const editor = useEditor({
    extensions,
    editorProps: {
      attributes: {
        class: editorBody
      }
    },
    content,
    onUpdate: ({ editor }) => {
      setValue(name, editor.getHTML())
    }
  })
  useEffect(() => {
    if (editor && content !== editor.getHTML()) {
      editor.commands.setContent(content)
    }
  }, [content, editor])
  return (
    <div className={inputWrapper}>
      {label && (
        <label htmlFor={name} className={inputLabel}>
          {label}
        </label>
      )}
      <div className={clsx(editorContainer, error !== null && inputError)}>
        <EditorContent editor={editor} />
        <BubbleMenu editor={editor} className={editorBubbleMenu}>
          {editor && <BaseModifiers editor={editor} />}
        </BubbleMenu>
        <div className={editorBottom}>
          {editor && (
            <div className={editorMenuBottom}>
              {hasAttachments && (
                <AttachmentAddInput
                  className={editorButtonToggle}
                  control={control}
                />
              )}
              <BaseModifiers editor={editor} />
            </div>
          )}
          {hasAttachments && (
            <AttachmentsInput control={control} watch={watch} />
          )}
        </div>
      </div>
      {error !== null && (
        <div className={errorMessage} role="alert">
          {error.message}
        </div>
      )}
    </div>
  )
}
