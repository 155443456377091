import { useMutation } from '@tanstack/react-query'
import { Link, createFileRoute, useNavigate } from '@tanstack/react-router'
import React, { useEffect, useState } from 'react'
import z from 'zod'
import { type LoginParams, login } from '~/api/mutations/login'
import type { UserInfosResponse } from '~/api/queries/user'
import { ResponseError, fetchApiWithToken } from '~/api/utils'
import {
  Button,
  ButtonVariation
} from '~/components/Global/Interactions/Button'
import { Login as LoginForm } from '~/forms/Login'
import { contactCTA, lostPasswordTrigger } from '~/pages/styles/Home.css'
import { formWrapper } from '~/pages/styles/Login.css'

const validateSearch = z.object({
  instance: z.string().optional(),
  i: z.string().optional(),
  error: z.string().optional()
})

const Index = () => {
  const { error, instance, i } = Route.useSearch()
  const navigate = useNavigate()
  const mutation = useMutation({ mutationFn: login })
  const [globalError, setGlobalError] = useState<string>('')
  const [success, setSuccess] = useState<boolean>(false)

  useEffect(() => {
    switch (error) {
      case 'instance':
        setGlobalError('Instance inconnue')
        break
      case 'logout':
        setGlobalError('Vous avez été déconnecté')
        break
    }
  }, [error])

  const onSubmit = async (data: LoginParams): Promise<void> => {
    try {
      setGlobalError('')
      const instance: string = data.instance
        .replace(/[^a-z0-9\-.]/i, '')
        .trim()
        .toLocaleLowerCase()
      await mutation.mutateAsync(
        {
          instance: instance,
          email: data.email,
          password: data.password
        },
        {
          onSuccess: async (reponse) => {
            const apiEndpoint: string = import.meta.env.VITE_API_ENDPOINT
            const token: string = reponse?.token ?? ''
            if (token.length === 0) {
              setGlobalError(
                'Une erreur interne est survenue lors de votre identification.'
              )
              return
            }
            try {
              const urlParams = new URLSearchParams()
              urlParams.append('email', data.email)
              const user = (await fetchApiWithToken({
                url: `/v1/users?${urlParams.toString()}`,
                method: 'get',
                forceToken: token,
                forceInstance: instance
              })) as UserInfosResponse[]
              if (user[0] === undefined) {
                setGlobalError(
                  'Une erreur est survenue lors de la récupération de votre compte.'
                )
                return
              }
              localStorage.setItem('id', String(user[0].id))
              localStorage.setItem('token', token)
              localStorage.setItem('instance', instance)
              localStorage.setItem('email', user[0].email)
              localStorage.removeItem('mainToken')
              localStorage.removeItem('mainUser')
              setSuccess(true)
              window.location.href = `${apiEndpoint}/v1/auth/bridge/${instance}/${token}`
            } catch (e) {
              setGlobalError(
                'Une erreur est survenue lors de la récupération de votre compte.'
              )
              return
            }
          }
        }
      )
    } catch (e) {
      if (e instanceof ResponseError) {
        if (e.response.status === 401) {
          setGlobalError('Email ou mot de passe incorrect')
          return
        }
        if (e.response.status === 404) {
          setGlobalError('Instance inconnue')
          return
        }
      }
      if (mutation.error instanceof Error) {
        setGlobalError(mutation.error.message ?? 'Une erreur est survenue')
      }
      setGlobalError('Erreur interne')
    }
  }
  if (success) {
    void navigate({ to: '/bridge' })
    return null
  }
  return (
    <>
      <div className={formWrapper}>
        <LoginForm
          onSubmit={onSubmit}
          error={globalError}
          defaultValues={{
            instance: instance ?? i
          }}
        />
        <div className={lostPasswordTrigger}>
          <Link to={'/forgot-password'}>
            <Button variation={ButtonVariation.Link}>
              Mot de passe oublié ?
            </Button>
          </Link>
        </div>
        <div className={contactCTA}>
          <Button
            variation={ButtonVariation.Secondary}
            buttonSize="small"
            href="https://job-explorer.fr/?om=contact"
          >
            Pas encore de compte ? Contactez nous
          </Button>
        </div>
      </div>
    </>
  )
}

export const Route = createFileRoute('/_login/')({
  component: Index,
  validateSearch
})
