import { type MutableRefObject, useEffect } from 'react'

export const useOnExternalClick = (
  ref: MutableRefObject<HTMLElement | null>,
  callback: () => void
): void => {
  useEffect(() => {
    const handleClick = ({ target }: MouseEvent): void => {
      if (
        ref.current !== null &&
        target instanceof Node &&
        !ref.current?.contains(target)
      ) {
        callback()
      }
    }
    document.addEventListener('click', handleClick)
    return (): void => {
      document.removeEventListener('click', handleClick)
    }
  }, [callback, ref])
}
