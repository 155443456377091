import {
  faDownload,
  faMinus,
  faPlus,
  faUserSecret
} from '@fortawesome/free-solid-svg-icons'
import {
  faArrowsMaximize,
  faHourglassHalf,
  faImageSlash
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import {
  useApplicantPreviewsResumes,
  useApplicantResumes
} from '~/api/queries/applicant'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'
import { useBrandInfos } from '~/api/queries/global'
import { ButtonGroup } from '~/components/Global/Blocks/ButtonGroup/ButtonGroup'
import { Shimmer } from '~/components/Global/Elements/Shimmer/Shimmer'
import { Tooltip } from '~/components/Global/Elements/Tooltip/Tooltip'
import {
  Button,
  ButtonVariation
} from '~/components/Global/Interactions/Button'
import {
  applicantResumeViewer,
  applicantResumeViewerFullscreen,
  applicantResumeViewerLegacy,
  applicantResumeViewerToolbar,
  applicantResumeViewerToolbarItem,
  applicantResumeViewerToolbarItemActionnable,
  applicantResumeViewerToolbarItemDisabled,
  applicantResumeViewerToolbarItemScale,
  applicantResumeViewerToolbarName,
  applicantResumeViewerToolbarNameIcon,
  noResume,
  noResumeIcon,
  previewGeneratingSubtitle,
  previewGeneratingTitle
} from '~/components/Scopes/Applicants/ApplicantModal/ApplicantResumeViewer.css'
import { getIconFromResume } from '~/components/Scopes/Applicants/Resume'
import { themeClass } from '~/styles/theme.css'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`

export const ApplicantResumeViewer = ({
  applicantId,
  defaultScale,
  legacyStyle,
  canAnonymize
}: {
  applicantId: number
  defaultScale?: number
  legacyStyle?: boolean
  canAnonymize?: boolean
}) => {
  const { data: applicantResume, isLoading: isPreviewLoading } =
    useApplicantPreviewsResumes({
      ids: [applicantId]
    })
  const { data: applicantOriginalResume, isLoading: isResumeLoading } =
    useApplicantResumes({
      ids: [applicantId],
      withBase64: applicantResume?.[0] === undefined
    })
  const [numPages, setNumPages] = useState<number>()
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [scale, setScale] = useState(defaultScale ?? 1)
  const previewRef = useRef<HTMLDivElement>(null)
  const [previewWidth, setPreviewWidth] = useState<number>()
  const { data: brandInfos } = useBrandInfos()

  useEffect(() => {
    const fullscreenListener = () => {
      setIsFullScreen(!!document.fullscreenElement)
    }
    document.addEventListener('fullscreenchange', fullscreenListener)
    return () => {
      document.removeEventListener('fullscreenchange', fullscreenListener)
    }
  }, [])

  const applicantOriginalResumeData = applicantOriginalResume?.[0] ?? null
  const applicantResumePreviewData = applicantResume?.[0] ?? null

  const updatePreviewWidth = useCallback(() => {
    const wrapperWidth = previewRef.current?.clientWidth ?? 0
    setPreviewWidth(wrapperWidth - 30)
  }, [])

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages)
    updatePreviewWidth()
  }

  useEffect(() => {
    window.addEventListener('resize', updatePreviewWidth)
    return () => {
      window.removeEventListener('resize', updatePreviewWidth)
    }
  }, [updatePreviewWidth])

  if (
    isPreviewLoading ||
    (!applicantResumePreviewData?.base64Content && isResumeLoading)
  ) {
    return <Shimmer height={400} />
  }

  if (!isResumeLoading && !applicantOriginalResumeData) {
    return (
      <div className={clsx(noResume, themeClass)}>
        <div className={noResumeIcon}>
          <FontAwesomeIcon icon={faImageSlash} />
        </div>
        Aucun CV disponible
      </div>
    )
  }

  if (
    applicantOriginalResumeData?.resumeData.icon !== 'file-pdf' &&
    !applicantResumePreviewData
  ) {
    return (
      <div className={clsx(noResume, themeClass)}>
        <div className={noResumeIcon}>
          <FontAwesomeIcon icon={faHourglassHalf} />
        </div>
        <div className={previewGeneratingTitle}>
          Génération de l'aperçu du CV en cours...
        </div>
        <div className={previewGeneratingSubtitle}>
          Cette opération peut prendre quelques minutes.
        </div>
      </div>
    )
  }

  const resumeDownloadUrl =
    applicantOriginalResumeData?.resumeData.link?.indexOf('/admin') !== -1
      ? `${brandInfos?.legacyUrl}${applicantOriginalResumeData?.resumeData?.link}`
      : applicantOriginalResumeData?.resumeData.link
  return (
    <div
      className={clsx(
        applicantResumeViewer,
        themeClass,
        isFullScreen ? applicantResumeViewerFullscreen : null,
        legacyStyle ? applicantResumeViewerLegacy : null
      )}
      ref={previewRef}
    >
      {numPages && (
        <div className={applicantResumeViewerToolbar}>
          <span className={applicantResumeViewerToolbarName}>
            <FontAwesomeIcon
              icon={getIconFromResume({
                icon: applicantOriginalResumeData?.resumeData.icon
              })}
              className={applicantResumeViewerToolbarNameIcon}
            />
            {applicantResumePreviewData?.resumeName}
          </span>
          <span className={applicantResumeViewerToolbarItem}>
            <span
              className={applicantResumeViewerToolbarItemActionnable}
              onClick={(e) => {
                e.preventDefault()
                setScale((scale) => Math.max(scale - 0.1, 0.1))
              }}
            >
              <FontAwesomeIcon icon={faMinus} />
            </span>
            <span
              onClick={(e) => {
                e.preventDefault()
                setScale(1)
              }}
              className={clsx(
                applicantResumeViewerToolbarItemScale,
                scale === 1
                  ? applicantResumeViewerToolbarItemDisabled
                  : applicantResumeViewerToolbarItemActionnable
              )}
            >
              {Math.floor(scale * 100)}%
            </span>
            <span
              className={applicantResumeViewerToolbarItemActionnable}
              onClick={(e) => {
                e.preventDefault()
                setScale((scale) => scale + 0.1)
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
            </span>
          </span>

          <Tooltip
            text={isFullScreen ? 'Réduire' : 'Plein écran'}
            direction="bottom"
          >
            <span
              className={clsx(
                applicantResumeViewerToolbarItem,
                applicantResumeViewerToolbarItemActionnable
              )}
              onClick={(e) => {
                e.preventDefault()
                if (!document.fullscreenElement) {
                  void document.documentElement.requestFullscreen()
                } else if (document.exitFullscreen) {
                  void document.exitFullscreen()
                }
              }}
            >
              <FontAwesomeIcon icon={faArrowsMaximize} />
            </span>
          </Tooltip>

          {resumeDownloadUrl && (
            <Tooltip text="Télécharger le CV" direction="bottom">
              <span
                className={clsx(
                  applicantResumeViewerToolbarItem,
                  applicantResumeViewerToolbarItemActionnable
                )}
                onClick={(e) => {
                  e.preventDefault()
                  window.open(resumeDownloadUrl)
                }}
              >
                <FontAwesomeIcon icon={faDownload} />
              </span>
            </Tooltip>
          )}
          {canAnonymize && (
            <Tooltip text="Anonymiser le CV" direction="bottom">
              <span
                className={clsx(
                  applicantResumeViewerToolbarItem,
                  applicantResumeViewerToolbarItemActionnable
                )}
                onClick={(e) => {
                  e.preventDefault()
                  window.location.href = `/admin/anonymize/index/${applicantOriginalResumeData?.resumeData.attachmentId}`
                }}
              >
                <FontAwesomeIcon icon={faUserSecret} />
              </span>
            </Tooltip>
          )}
        </div>
      )}
      <Document
        file={`data:application/pdf;base64,${applicantResumePreviewData?.base64Content ?? applicantOriginalResumeData?.resumeData.base64Content}`}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={<Shimmer height={400} />}
        error={
          <div className={noResume}>
            <div className={noResumeIcon}>
              <FontAwesomeIcon icon={faImageSlash} />
            </div>
            Erreur lors du chargement du CV
          </div>
        }
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            scale={scale}
            width={previewWidth}
          />
        ))}
      </Document>
    </div>
  )
}
