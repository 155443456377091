import {
  type InfiniteData,
  useMutation,
  useQueryClient
} from '@tanstack/react-query'
import type {
  ApplicantReportsLDResponse,
  ApplicantReportsResponse
} from '~/api/queries/applicant'
import { type ResponseError, fetchApiWithToken } from '~/api/utils'
import { ToastType } from '~/components/Global/Interactions/Toast/Toast'
import { toast } from '~/utils'

export type InterviewReportPayload = {
  id?: number
  title: string
  sections: {
    name: string
    content: string
  }[]
}

export const useInterviewReportMutation = (applicantId?: number) => {
  const queryClient = useQueryClient()
  return useMutation<
    ApplicantReportsResponse,
    ResponseError,
    InterviewReportPayload,
    { reports?: InfiniteData<ApplicantReportsLDResponse> }
  >({
    mutationFn: ({ id, title, sections }) =>
      fetchApiWithToken({
        method: id ? 'put' : 'post',
        url: `/v1/applicants/${applicantId}/interview_reports${id ? `/${id}` : ''}`,
        data: {
          title,
          interviewReportSections: sections
        }
      }),
    scope: {
      // We use a scope to queue autosave requests
      id: `report-${applicantId}`
    },
    onMutate: async (payload) => {
      // Cancel the query for the order to avoid our optimistic update to be overwritten
      void queryClient.cancelQueries({
        queryKey: ['applicant', applicantId, 'reports']
      })
      const reports = queryClient.getQueryData<
        InfiniteData<ApplicantReportsLDResponse>
      >(['applicant', applicantId, 'reports'])
      if (reports) {
        if (payload.id) {
          // If it's an existing report, update it in the right page cache
          void queryClient.setQueryData(['applicant', applicantId, 'reports'], {
            ...reports,
            pages: reports.pages.map((page) => ({
              ...page,
              'hydra:member': page['hydra:member'].map((report) =>
                report.id === payload.id
                  ? {
                      ...report,
                      title: payload.title,
                      interviewReportSections: payload.sections
                    }
                  : report
              )
            }))
          })
        } else {
          // If it's a new report, add it to the first page
          void queryClient.setQueryData(['applicant', applicantId, 'reports'], {
            ...reports,
            pages: reports.pages.map((page, i) =>
              i === 0
                ? {
                    ...page,
                    'hydra:member': [
                      {
                        id: Math.random(),
                        title: payload.title,
                        interviewReportSections: payload.sections
                      },
                      ...page['hydra:member']
                    ]
                  }
                : page
            )
          })
        }
      }
      return { reports }
    },
    onSettled: () => {
      void queryClient.invalidateQueries({
        queryKey: ['applicant', applicantId, 'reports']
      })
    },
    onError: (e, __, context) => {
      if (context?.reports) {
        void queryClient.setQueryData(
          ['applicant', applicantId, 'reports'],
          context.reports
        )
      }
      if (e.httpStatus !== 403 && e.httpStatus !== 401) {
        toast(
          'Une erreur est survenue',
          'Une erreur est survenue lors de la mise à jour du compte rendu',
          ToastType.Danger
        )
      }
    }
  })
}

export const useInterviewReportDeleteMutation = (applicantId?: number) => {
  const queryClient = useQueryClient()
  return useMutation<
    boolean,
    ResponseError,
    { id: number },
    { reports?: InfiniteData<ApplicantReportsLDResponse> }
  >({
    mutationFn: ({ id }) =>
      fetchApiWithToken<boolean>({
        method: 'delete',
        url: `/v1/applicants/${applicantId}/interview_reports/${id}`
      }),
    onMutate: async ({ id }) => {
      // Cancel the query for the order to avoid our optimistic update to be overwritten
      void queryClient.cancelQueries({
        queryKey: ['applicant', applicantId, 'reports']
      })
      const reports = queryClient.getQueryData<
        InfiniteData<ApplicantReportsLDResponse>
      >(['applicant', applicantId, 'reports'])
      if (reports) {
        void queryClient.setQueryData(['applicant', applicantId, 'reports'], {
          ...reports,
          pages: reports.pages.map((page) => ({
            ...page,
            'hydra:member': page['hydra:member'].filter(
              (report) => report.id !== id
            )
          }))
        })
      }
      return { reports }
    },
    onSettled: () => {
      void queryClient.invalidateQueries({
        queryKey: ['applicant', applicantId, 'reports']
      })
    },
    onError: (e, __, context) => {
      if (context?.reports) {
        void queryClient.setQueryData(
          ['applicant', applicantId, 'reports'],
          context.reports
        )
      }
      if (e.httpStatus !== 403 && e.httpStatus !== 401) {
        toast(
          'Une erreur est survenue lors de la suppression',
          'Si le compte rendu a été créé récemment, veuillez réessayer.',
          ToastType.Danger
        )
      }
    }
  })
}
