import { faChevronDown, faTimes } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'
import React, { type ReactElement, useEffect, useRef, useState } from 'react'
import { useFieldArray } from 'react-hook-form'
import type { Control } from 'react-hook-form/dist/types/form'
import {
  attachmentDropdown,
  attachmentDropdownItem,
  attachmentDropdownTop,
  attachmentDropdownTrigger,
  attachmentDropdownWrapper,
  attachmentItem,
  attachmentLabel,
  attachmentRemove,
  attchmentsList
} from '~/components/Form/Input/AttachmentInput.css'
import { openIconOpen } from '~/components/Form/Input/Dropdown.css'
import type { InputInFormProps } from '~/components/Form/Input/input'
import { getIconFromMime } from '~/components/Global/Elements/File/FileType'
import { useOnExternalClick } from '~/utils/interactions'

export type AttachmentField =
  | {
      fromTemplate: true
      id?: string
      object: {
        id: number
        uri: string
        filename: string | null
        size: number | null
        mime?: string | null
      }
    }
  | {
      fromTemplate: false
      id?: string
      object: {
        base64_content: string
        filename?: string | null
        size?: number | null
        mime?: string | null
      }
    }

type AttachmentsInputProps = InputInFormProps & {
  control?: Control<{
    attachments: AttachmentField[]
  }>
}

export const AttachmentsInput = ({
  control,
  watch
}: AttachmentsInputProps): ReactElement => {
  const [isOpen, setIsOpen] = useState(false)
  const [openTop, setOpenTop] = useState(false)
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const dropdownRef = useRef<HTMLDivElement | null>(null)

  if (control === undefined || watch === undefined) {
    throw new Error('AttachmentsInput must live inside a Form component')
  }
  const { remove } = useFieldArray({
    control,
    name: 'attachments'
  })
  const attachments = watch('attachments')

  useOnExternalClick(wrapperRef, () => setIsOpen(false))

  useEffect(() => {
    if (dropdownRef.current === null || !isOpen) {
      setOpenTop(false)
      return
    }
    const { y, height } = dropdownRef.current.getBoundingClientRect()
    setOpenTop(y + height > window.innerHeight)
  }, [isOpen])

  if (!attachments) {
    return <></>
  }

  if (attachments.length <= 2) {
    return (
      <div className={attchmentsList}>
        {attachments.map((attachment: any, index: number) => (
          <div key={attachment.id} className={attachmentItem}>
            <FontAwesomeIcon
              icon={getIconFromMime({ mime: attachment.object.mime })}
            />
            <span className={attachmentLabel}>
              {attachment.object.filename}
            </span>
            {!attachment.fromTemplate && (
              <button
                type="button"
                onClick={() => remove(index)}
                title="Supprimer la pièce jointe"
                className={attachmentRemove}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            )}
          </div>
        ))}
      </div>
    )
  }

  return (
    <div
      className={clsx(attachmentDropdownWrapper, attachmentItem)}
      ref={wrapperRef}
      onClick={() => setIsOpen(!isOpen)}
    >
      <strong>+{attachments.length}</strong> pièces jointes
      <button type="button" className={attachmentDropdownTrigger}>
        <FontAwesomeIcon
          icon={faChevronDown}
          className={clsx(isOpen ? openIconOpen : null)}
        />
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className={clsx(
              attachmentDropdown,
              openTop ? attachmentDropdownTop : null
            )}
            ref={dropdownRef}
          >
            {attachments.map((attachment: any, index: number) => (
              <div key={attachment.id} className={attachmentDropdownItem}>
                <FontAwesomeIcon
                  icon={getIconFromMime({ mime: attachment.object.mime })}
                />
                <span className={attachmentLabel}>
                  {attachment.object.filename}
                </span>
                {!attachment.fromTemplate && (
                  <button
                    type="button"
                    onClick={() => remove(index)}
                    title="Supprimer la pièce jointe"
                    className={attachmentRemove}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                )}
              </div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
