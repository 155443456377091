/**
 * @see https://tiptap.dev/docs/editor/extensions/nodes/emoji
 */

import { type Editor, ReactRenderer } from '@tiptap/react'
import tippy, { type GetReferenceClientRect, type Instance } from 'tippy.js'

import type { EmojiItem } from '@tiptap-pro/extension-emoji/dist/tiptap-pro/packages/extension-emoji/src/emoji'
import type { SuggestionOptions } from '@tiptap/suggestion'
import { EmojiList } from './EmojiList'

const emojiSuggestion: Omit<SuggestionOptions<EmojiItem>, 'editor'> = {
  items: ({ editor, query }: { editor: Editor; query: string }) => {
    return (editor.storage.emoji.emojis as EmojiItem[])
      .filter(({ shortcodes, tags }) => {
        return (
          shortcodes.find((shortcode) =>
            shortcode.startsWith(query.toLowerCase())
          ) || tags.find((tag) => tag.startsWith(query.toLowerCase()))
        )
      })
      .slice(0, 5)
  },

  allowSpaces: false,

  render: () => {
    // @ts-ignore
    let component: EmojiList
    let popup: Instance[]

    return {
      onStart: (props) => {
        component = new ReactRenderer(EmojiList, {
          props,
          editor: props.editor
        })

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect as GetReferenceClientRect,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start'
        })
      },

      onUpdate(props) {
        component.updateProps(props)

        if (!props.clientRect) {
          return
        }

        popup[0]?.setProps({
          getReferenceClientRect: props.clientRect as GetReferenceClientRect
        })
      },

      onKeyDown({ event }: { event: KeyboardEvent }) {
        if (event.key === 'Escape') {
          popup[0]?.hide()
          component.destroy()

          return true
        }

        return component.ref?.onKeyDown({ event })
      },

      onExit() {
        popup[0]?.destroy()
        component.destroy()
      }
    }
  }
}

export default emojiSuggestion
