import { AnimatePresence, motion } from 'framer-motion'
import { type ReactElement, useState } from 'react'
import { Toast, type ToastProps, ToastType } from './Toast'
import { toastStack } from './Toast.css'
import { getToaster } from './getToaster'

export const ToastStack = (): ReactElement => {
  const [toasts, setToasts] = useState<Array<ToastProps & { id: string }>>([])
  getToaster().current = (
    title?: string,
    content?: string,
    type?: ToastType
  ) => {
    const id = Math.random().toString(36)
    // Do not add the same toast twice
    for (const toast of toasts) {
      if (toast.title === title && toast.content === content) {
        return false
      }
    }
    setToasts([...toasts, { title, content, type, id }])
    setTimeout(() => {
      setToasts((toasts) => toasts.filter((toast) => toast.id !== id))
    }, 5000)
    return true
  }
  return (
    <div className={toastStack}>
      <AnimatePresence>
        {toasts.map(({ title, content, type, id }) => (
          <motion.div
            key={id}
            initial={{ opacity: 0, x: -200, zIndex: 999, position: 'relative' }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -200, zIndex: 999, position: 'relative' }}
          >
            <Toast
              key={id}
              type={type ?? ToastType.Default}
              title={title ?? ''}
              content={content ?? ''}
              onClose={() =>
                setToasts(toasts.filter((toast) => toast.id !== id))
              }
            />
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  )
}
