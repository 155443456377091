import clsx from 'clsx'
import React, { type ReactElement, useEffect, useState } from 'react'
import { submitAlignments, submitWrapper } from '~/components/Form/Form.css'
import {
  Button,
  type ButtonProps,
  ButtonVariation
} from '~/components/Global/Interactions/Button'

export enum SubmitAlignment {
  Left = 'left',
  Center = 'center',
  Right = 'right',
  None = 'none'
}

interface SubmitProps extends ButtonProps {
  loadingText?: string
  successText?: string
  errorText?: string
  onSuccessReset?: boolean
  globalError?: null | string
  globalErrorInSubmit?: boolean
  isSubmitted?: boolean
  isSubmitting?: boolean
  isSubmitSuccessful?: boolean
  buttonVariation?: ButtonVariation
  submitAlignment?: SubmitAlignment
}

export const Submit = ({
  children,
  onSuccessReset = false,
  globalError,
  globalErrorInSubmit,
  loadingText,
  successText,
  errorText = 'Une erreur est survenue',
  isSubmitted,
  isSubmitting,
  isSubmitSuccessful,
  buttonVariation = ButtonVariation.Primary,
  submitAlignment = SubmitAlignment.None,
  ...rest
}: SubmitProps): ReactElement => {
  const hasGlobalError =
    globalError !== '' || globalError === undefined || globalError === null
  if (children === undefined || children === null) {
    throw new Error('Submit must have children')
  }
  if (errorText === undefined) {
    throw new Error('Submit must have an error text')
  }
  if (isSubmitting === undefined || isSubmitSuccessful === undefined) {
    throw new Error('Submit must be in a form')
  }
  const [onError, setOnError] = useState<boolean>(false)

  let submitText = children
  if (isSubmitting) {
    submitText = loadingText !== undefined ? loadingText : 'Chargement...'
  } else if (onError) {
    submitText =
      globalErrorInSubmit === true ? (globalError ?? errorText) : errorText
  } else if (isSubmitSuccessful && !hasGlobalError) {
    submitText = successText !== undefined ? successText : 'Enregistré'
  }

  useEffect(() => {
    let timer: number | undefined
    if (
      isSubmitted &&
      !isSubmitting &&
      (!isSubmitSuccessful || hasGlobalError)
    ) {
      setOnError(true)
      timer = window.setTimeout(() => {
        setOnError(false)
      }, 3000)
    }
    return () => {
      timer && window.clearTimeout(timer)
    }
  }, [isSubmitSuccessful, isSubmitting, isSubmitted, hasGlobalError])

  const isDisabled: boolean =
    isSubmitting || (isSubmitSuccessful && !hasGlobalError && !onSuccessReset)
  const buttonStyle: ButtonVariation = onError
    ? ButtonVariation.Danger
    : isSubmitSuccessful && !hasGlobalError
      ? ButtonVariation.Secondary
      : buttonVariation

  return (
    <div
      className={clsx(
        submitAlignment !== SubmitAlignment.None
          ? submitAlignments[submitAlignment]
          : undefined,
        submitWrapper
      )}
    >
      <Button
        type="submit"
        disabled={isDisabled}
        variation={buttonStyle}
        {...rest}
      >
        {submitText}
      </Button>
    </div>
  )
}
