import { SSE } from 'sse.js'
import { ToastType } from '~/components/Global/Interactions/Toast/Toast'
import { toast } from '~/utils'

const API_ENDPOINT = import.meta.env.VITE_API_ENDPOINT

type fetchOptions = RequestInit & {
  headers: HeadersInit & {
    'x-INSTANCE': string
    'x-JWT'?: string
    Accept: string
    'Content-Type'?: string
  }
}

export class ResponseError extends Error {
  public response: Response
  public httpStatus?: number
  constructor(message: string, res: Response, httpStatus?: number) {
    super(message)
    this.response = res
    this.httpStatus = httpStatus
  }
}

export type ApiResponseError = {
  response?: {
    code?: number
    message?: string
  }
}

const buildFetchOptions = (
  method: string,
  data?: object,
  useLDJson?: boolean,
  instance?: string,
  signal?: AbortSignal
) => {
  const options: fetchOptions = {
    method: method ?? 'GET',
    headers: {
      'x-INSTANCE': instance ?? String(localStorage.getItem('instance')),
      Accept: useLDJson ? 'application/ld+json' : 'application/json'
    }
  }
  if (signal) {
    options.signal = signal
  }
  if (data) {
    options.body = JSON.stringify(data)
    options.headers['Content-Type'] =
      method === 'PATCH' ? 'application/merge-patch+json' : 'application/json'
  }
  return options
}

interface fetchApiParams {
  url: string
  method?: string
  data?: object
  instance: string
  useLDJson?: boolean
  signal?: AbortSignal
}

export const fetchApi = async ({
  url,
  method,
  data,
  instance,
  useLDJson,
  signal
}: fetchApiParams) => {
  const init = buildFetchOptions(
    method ?? 'GET',
    data,
    useLDJson,
    instance,
    signal
  )
  init.headers['x-INSTANCE'] = (
    instance ?? String(localStorage.getItem('instance'))
  )
    .trim()
    .toLowerCase()

  const res = await fetch(API_ENDPOINT + url, init)
  if (!res.ok) {
    throw new ResponseError('Bad fetch response', res)
  }
  if (res.status === 204) {
    return
  }
  return res.json()
}

interface fetchAuthApiParams {
  url: string
  method?: string
  data?: object
  forceInstance?: string
  forceToken?: string
  useLDJson?: boolean
  signal?: AbortSignal
}

export const fetchApiWithToken = async <TResponse>({
  url,
  method,
  data,
  forceInstance,
  forceToken,
  useLDJson,
  signal
}: fetchAuthApiParams): Promise<TResponse> => {
  let instance = localStorage.getItem('instance')
  if (!instance) {
    instance = window.JobConfig?.instance?.computedName ?? ''
  }
  if (forceInstance) {
    instance = forceInstance
  }
  let token = localStorage.getItem('token')
  if (!token) {
    token = window.JobConfig?.user.jwt ?? ''
  }
  if (forceToken) {
    token = forceToken
  }
  if (!token || !instance) {
    throw new Error('Token or instance is missing')
  }
  const init = buildFetchOptions(
    method ?? 'GET',
    data,
    useLDJson,
    instance,
    signal
  )
  init.headers['x-JWT'] = forceToken ?? String(token)
  if (forceInstance) {
    init.headers['x-INSTANCE'] = forceInstance
  }

  const res = await fetch(API_ENDPOINT + url, init)
  if (!res.ok) {
    if (res.status === 401) {
      console.warn('Token expired')
      localStorage.removeItem('id')
      localStorage.removeItem('token')
      window.location.reload() // Temporary solution cause we cant access router here
    }
    if (res.status === 403) {
      toast(
        'Une erreur est survenue',
        "Vous n'avez pas les droits pour effectuer cette action",
        ToastType.Danger
      )
    }
    throw new ResponseError('Bad fetch response', await res.json(), res.status)
  }
  if (res.status === 204) {
    return Promise.resolve(true as TResponse)
  }
  return res.json()
}

export const buildSSEClient = (url: string, options: object): SSE => {
  if (
    localStorage.getItem('instance') === null ||
    localStorage.getItem('token') === null
  ) {
    throw new Error('Token or instance is missing')
  }

  return new SSE(`${String(API_ENDPOINT)}/${url}`, {
    headers: {
      'Content-Type': 'application/json',
      'x-INSTANCE': String(localStorage.getItem('instance')),
      'x-JWT': String(localStorage.getItem('token')),
      Accept: 'application/json'
    },
    method: 'POST',
    payload: JSON.stringify(options)
  })
}
