import {
  type InfiniteData,
  type UseQueryResult,
  useInfiniteQuery,
  useQuery
} from '@tanstack/react-query'
import type { AclsStructure } from '~/components/Layout/GlobalMenu/menu'
import type { UserGroup } from '~/types/global'
import { fetchApiWithToken } from '../utils'

export interface UserInfosResponse {
  email: string
  id: number
  firstname: string
  lastname: string
  phone: string
  civility: string
  function?: string
  isSuperUser: boolean
  groups: UserGroup[]
  agencies: {
    id: number
    name: string
  }[]
  groupMain: UserGroup
  notificationUnreadCount: number
}

export const fetchUsers = ({
  usersIds,
  signal
}: { usersIds: number[]; signal?: AbortSignal }) =>
  fetchApiWithToken<UserInfosResponse[]>({
    url: `/v1/users?id[]=${usersIds.join('&id[]=')}`,
    signal
  })

export const searchUsersByName = ({
  search,
  signal,
  listingContext
}: { search: string; signal?: AbortSignal, listingContext?: string }) =>
  fetchApiWithToken<UserInfosResponse[]>({
    url: `/v1/users?fullname=${search}${listingContext ? `&listingContext=${listingContext}` : ''}`,
    signal
  })

export const useUsers = (): UseQueryResult<UserInfosResponse[], Error> =>
  useQuery({
    queryKey: ['users'],
    queryFn: async () =>
      await fetchApiWithToken({
        method: 'get',
        url: '/v1/users'
      }),
    refetchOnWindowFocus: false,
    staleTime: 1800_000
  })

export const useCurrentUserInfos = (): UseQueryResult<
  UserInfosResponse[],
  Error
> => {
  return useQuery({
    queryKey: ['user', 'infos'],
    queryFn: async () =>
      await fetchApiWithToken({
        method: 'get',
        url: `/v1/users?id=${String(localStorage.getItem('id'))}`
      }),
    refetchOnWindowFocus: false,
    staleTime: 1800_000,
    enabled:
      localStorage.getItem('id') !== null && localStorage.getItem('id') !== ''
  })
}

export const useMeInfos = (): UserInfosResponse | null => {
  const { data } = useCurrentUserInfos()
  return data?.[0] ?? null
}

export const useCurrentGroupId = (): number | null => {
  const meInfos = useMeInfos()
  const localCurrentGroup = localStorage.getItem('currentGroup')
  return localCurrentGroup !== null
    ? Number.parseInt(localCurrentGroup)
    : (meInfos?.groupMain?.id ?? null)
}

export const useUserTokenRefresh = (): UseQueryResult<
  { token: string },
  Error
> =>
  useQuery({
    queryKey: ['user', 'token'],
    queryFn: async () =>
      await fetchApiWithToken({
        method: 'get',
        url: `/v1/users/${String(localStorage.getItem('id'))}/token`
      }),
    staleTime: 1800_000,
    refetchOnWindowFocus: false,
    enabled:
      localStorage.getItem('id') !== null && localStorage.getItem('id') !== ''
  })

export const useUserAcls = (
  id: number | null
): UseQueryResult<AclsStructure, Error> =>
  useQuery({
    queryKey: ['user', id, 'acls'],
    queryFn: async () =>
      await fetchApiWithToken({
        method: 'get',
        url: `/v1/users/${id ?? ''}/acl`
      }),
    enabled: id !== null,
    refetchOnWindowFocus: false,
    staleTime: 1800_000
  })

interface UserPictureResponse {
  userId: number
  publicUrl: string
  object: {
    id: number
    filename: string
    size: number
  }
}

export const useUserPicture = (
  id: number | null
): UseQueryResult<UserPictureResponse, Error> =>
  useQuery({
    queryKey: ['user', 'picture', id],
    queryFn: async () =>
      await fetchApiWithToken({
        method: 'get',
        url: `/v1/users/${String(id)}/picture`
      }),
    staleTime: Number.POSITIVE_INFINITY,
    refetchOnWindowFocus: false,
    enabled: id !== null
  })

export const useUserSearchToken = (
  id: string | null
): UseQueryResult<{ token: string }, Error> =>
  useQuery({
    queryKey: ['user', 'searchToken', id],
    queryFn: async () =>
      await fetchApiWithToken({
        method: 'get',
        url: `/v1/users/${String(id)}/search-token`
      }),
    staleTime: 3600_000,
    refetchOnWindowFocus: false,
    enabled: id !== null
  })

export type UserPicturesResponse = {
  profilePicUrl: string
  user: string
}

export type UserNotificationType = 'application' | 'sms_reply' | 'stats_file' | 'sequence_added_recipients' | 'gdpr_anonymized_applicants'

export type UserNotification = {
  id: number
  type: UserNotificationType
  createdAt: string
  updatedAt: string
  slug: string
  isRead: boolean
  metadata: {
    count: number
    details: {
      [key: string]: string
    }
    stack: {
      [key: string]: string
    }[]
  }
}

export type RealTimeUserNotification = Omit<
  UserNotification,
  'createdAt' | 'updatedAt' | 'isRead'
> & {
  unreadCount: number
}

export type UserNotificationsLDResponse = {
  'hydra:member': UserNotification[]
  'hydra:totalItems': number
}

export const useCurrentUserNotifications = ({
  userId,
  active = false
}: {
  userId: number
  active?: boolean
}): {
  userNotifications: UserNotification[] | undefined
  userNotificationsTotal: number | undefined
  isLoading: boolean
  fetchNextPage: () => void
  hasNextPage: boolean
  isFetchingNextPage: boolean
} => {
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery<
      UserNotificationsLDResponse,
      Error,
      InfiniteData<UserNotificationsLDResponse>
    >({
      queryKey: ['user', 'notifications', userId],
      initialPageParam: 1,
      getNextPageParam: (lastPage, allPages) => {
        const currentPage = allPages.length
        if (lastPage['hydra:totalItems'] > currentPage * 30) {
          return currentPage + 1
        }
      },
      queryFn: async ({ pageParam }) => {
        return (await fetchApiWithToken({
          method: 'get',
          url: `/v1/users/${userId}/notifications?order[isRead]=asc&order[updatedAt]=desc&page=${pageParam}`,
          useLDJson: true
        })) as UserNotificationsLDResponse
      },
      enabled: !!userId && active,
      staleTime: 60_000
    })
  return {
    userNotifications: data?.pages.flatMap((page) => page['hydra:member']),
    userNotificationsTotal: data?.pages[0]?.['hydra:totalItems'],
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  }
}
