import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { getMonth, getYear } from 'date-fns'
import { fr } from 'date-fns/locale/fr'
import { forwardRef, useCallback, useState } from 'react'
import DatePicker, {
  type DatePickerProps as DatePickerPropsType,
  type ReactDatePickerCustomHeaderProps,
  registerLocale,
  setDefaultLocale
} from 'react-datepicker'
import { input } from '~/components/Form/Input/TextInput.css'
import {
  calendarHeader,
  dateInput,
  headerYear,
  inputPlaceholder,
  monthNavigation,
  monthNavigationDisabled,
  monthTitle,
  monthYearSelect,
  monthYearSelectItem,
  monthYearSelectItemActive
} from './Datepicker.css'
registerLocale('fr', fr)
setDefaultLocale('fr')

export type DatepickerProps = Pick<DatePickerPropsType, 'popperPlacement'> & {
  currentDate: Date | null
  onChange: (date: Date | null) => void
  placeholder?: string
  noPast?: boolean
  showTime?: boolean
  selectsStart?: boolean
  selectsEnd?: boolean
  startDate?: Date
  endDate?: Date
  minDate?: Date
  dateFormatOptions?: Intl.DateTimeFormatOptions
}

export const Datepicker = ({
  placeholder = 'Sélectionner une date',
  currentDate,
  onChange,
  noPast,
  showTime,
  minDate,
  dateFormatOptions,
  ...rest
}: DatepickerProps) => {
  const years = [...Array(12).keys()].map(
    (i) => new Date().getFullYear() + i - (noPast ? 0 : 5)
  )

  const CustomHeader = ({
    monthDate,
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled
  }: ReactDatePickerCustomHeaderProps) => {
    const [isMonthSelectOpen, setIsMonthSelectOpen] = useState(false)
    const [isYearSelectOpen, setIsYearSelectOpen] = useState(false)
    return (
      <div className={calendarHeader}>
        <button
          aria-label="Previous Month"
          className={clsx(
            monthNavigation,
            prevMonthButtonDisabled ? monthNavigationDisabled : null
          )}
          onClick={decreaseMonth}
          type="button"
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <span className={monthTitle}>
          <span onClick={() => setIsMonthSelectOpen(!isMonthSelectOpen)}>
            {monthDate.toLocaleString('fr-FR', {
              month: 'long'
            })}
          </span>
          <span
            className={headerYear}
            onClick={() => setIsYearSelectOpen(!isYearSelectOpen)}
          >
            {' '}
            {getYear(date)}
          </span>
          {isMonthSelectOpen && (
            <div className={monthYearSelect}>
              {[...Array(12).keys()].map((month) => (
                <span
                  key={month}
                  onClick={() => {
                    changeMonth(month)
                    setIsMonthSelectOpen(false)
                  }}
                  className={clsx(
                    monthYearSelectItem,
                    month === getMonth(date) ? monthYearSelectItemActive : null
                  )}
                >
                  {new Intl.DateTimeFormat('fr-FR', {
                    month: 'long'
                  }).format(new Date(0, month))}
                </span>
              ))}
            </div>
          )}

          {isYearSelectOpen && (
            <div className={monthYearSelect}>
              {years.map((year) => (
                <span
                  key={year}
                  onClick={() => {
                    changeYear(year)
                    setIsYearSelectOpen(false)
                  }}
                  className={clsx(
                    monthYearSelectItem,
                    year === getYear(date) ? monthYearSelectItemActive : null
                  )}
                >
                  {year}
                </span>
              ))}
            </div>
          )}
        </span>
        <button
          aria-label="Mois suivant"
          className={clsx(
            monthNavigation,
            nextMonthButtonDisabled ? monthNavigationDisabled : null
          )}
          onClick={increaseMonth}
          type="button"
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </div>
    )
  }

  const formatDate = (date: Date) =>
    new Intl.DateTimeFormat(
      'fr-FR',
      dateFormatOptions ?? {
        dateStyle: 'long',
        timeStyle: showTime ? 'short' : undefined
      }
    ).format(date)

  const DateInput = forwardRef<
    HTMLElement,
    {
      value?: string | null
      onClick?: () => void
    }
  >(({ value, onClick }, ref) => {
    const date = value ? new Date(value) : null
    return (
      <span
        ref={ref}
        onClick={onClick}
        className={clsx(input, dateInput, !date && inputPlaceholder)}
      >
        {date ? formatDate(date) : placeholder}
      </span>
    )
  })

  const filterPassedTime = useCallback(
    (time: Date) => {
      if (minDate) {
        minDate.setSeconds(0)
        minDate.setMilliseconds(0)
        return time > minDate
      }
      if (noPast) {
        return time > new Date()
      }
      return true
    },
    [noPast, minDate]
  )

  const filterPassedDate = useCallback(
    (date: Date) => {
      const now = new Date()
      now.setHours(0, 0, 0, 0)
      if (noPast) {
        return date >= now
      }
      return true
    },
    [noPast]
  )

  return (
    <DatePicker
      selected={currentDate}
      onChange={onChange}
      dateFormat={showTime ? 'MM/dd/yyyy HH:mm' : 'MM/dd/yyyy'}
      showTimeSelect={showTime}
      filterDate={filterPassedDate}
      filterTime={filterPassedTime}
      timeIntervals={15}
      timeFormat={showTime ? 'HH:mm' : undefined}
      portalId="appWrapper"
      customInput={<DateInput />}
      formatWeekDay={(nameOfDay) => nameOfDay.substring(0, 1).toUpperCase()}
      renderCustomHeader={(props) => <CustomHeader {...props} />}
      minDate={minDate}
      {...rest}
    />
  )
}
