import type { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import {
  faFileImage,
  faFilePdf,
  faFileWord
} from '@fortawesome/free-solid-svg-icons'
import { faFile } from '@fortawesome/pro-regular-svg-icons'

export const getIconFromMime = ({
  mime
}: {
  mime?: string
}): IconDefinition => {
  let icon = faFile
  if (!mime) {
    return icon
  }
  if (mime.includes('pdf')) {
    icon = faFilePdf
  } else if (mime.includes('word')) {
    icon = faFileWord
  } else if (mime.includes('image')) {
    icon = faFileImage
  }
  return icon
}
