import { motion } from 'framer-motion'
import React, { type PropsWithChildren, type ReactElement } from 'react'
import { container } from './Overlay.css'

type overlayProps = PropsWithChildren<{ onClick: () => void }>

export const Overlay = ({ children, onClick }: overlayProps): ReactElement => (
  <motion.div
    initial={{ backdropFilter: 'blur(10px) opacity(0)' }}
    animate={{ backdropFilter: 'blur(10px) opacity(1)' }}
    exit={{ backdropFilter: 'blur(10px) opacity(0)' }}
    transition={{ ease: 'linear', duration: 0.05 }}
    className={container}
    onClick={onClick}
  >
    {children}
  </motion.div>
)
