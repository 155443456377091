import { createFileRoute } from '@tanstack/react-router'
import React, { type ReactElement } from 'react'

const SettingsLayout = (): ReactElement | null => {
  return <></>
}

export const Route = createFileRoute('/_dashboard/_settings')({
  component: SettingsLayout,
})
