import type { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import React, { type ReactElement } from 'react'
import type { InputInFormProps } from '~/components/Form/Input/input'
import { PasswordHintInput } from './Elements/PasswordHint'
import {
  errorMessage,
  input,
  inputDate,
  inputError,
  inputField,
  inputHasLeadingIcon,
  inputLabel,
  inputLeadingIcon,
  inputLeadingText,
  inputPassword,
  inputSmall,
  inputWrapper,
  passwordVisibilityTrigger,
  passwordVisibilityTriggerOnError
} from './TextInput.css'

export interface TextInputProps
  extends React.HTMLProps<HTMLInputElement>,
    InputInFormProps {
  label?: string
  name?: string
  hasPasswordHint?: boolean
  error?: null | { message: string }
  standalone?: boolean
  wrapper?: boolean
  small?: boolean
  leadingIcon?: IconDefinition
  leadingText?: string
}

export const TextInput = ({
  control,
  register,
  label,
  name,
  hasPasswordHint,
  type,
  standalone = false,
  wrapper = true,
  small = false,
  error = null,
  leadingIcon,
  leadingText,
  watch,
  setValue,
  ...rest
}: TextInputProps): ReactElement => {
  const [passwordVisibility, setPasswordVisibility] = React.useState(false)
  let attributes = { ...rest }
  if (
    !standalone &&
    register !== undefined &&
    control !== undefined &&
    name !== undefined
  ) {
    attributes = { ...rest, ...register(name) }
  } else if (!standalone) {
    throw new Error('TextInput must be used inside a Form component')
  }
  const Input = (
    <>
      {leadingIcon && (
        <div className={inputLeadingIcon}>
          <FontAwesomeIcon icon={leadingIcon} />
        </div>
      )}
      {leadingText && <div className={inputLeadingText}>{leadingText}</div>}
      <input
        type={type === 'password' && passwordVisibility ? 'text' : type}
        className={clsx(
          input,
          type === 'password' && inputPassword,
          type === 'date' && inputDate,
          error !== null && inputError,
          small && inputSmall,
          leadingIcon && inputHasLeadingIcon,
          attributes.className
        )}
        aria-invalid={error !== null ? 'true' : 'false'}
        aria-label={label}
        {...attributes}
      />
    </>
  )
  if (type === 'hidden') {
    return Input
  }
  if (!wrapper) {
    return <div className={inputField}>{Input}</div>
  }
  return (
    <div className={inputWrapper}>
      {label && (
        <label htmlFor={name} className={inputLabel}>
          {label}
        </label>
      )}
      <div className={inputField}>
        {Input}
        {type === 'password' && (
          <div
            className={clsx(
              passwordVisibilityTrigger,
              error !== null && passwordVisibilityTriggerOnError
            )}
            onClick={() => setPasswordVisibility(!passwordVisibility)}
          >
            <FontAwesomeIcon icon={!passwordVisibility ? faEyeSlash : faEye} />
          </div>
        )}
      </div>
      {hasPasswordHint === true && control !== undefined && (
        <PasswordHintInput control={control} />
      )}
      {error !== null && (
        <div className={errorMessage} role="alert">
          {error.message}
        </div>
      )}
    </div>
  )
}
